.product_detail_wrapper {
  // border:1px solid red;
  background-color: white;
  margin-top: 4px;
  .back {
    color: #ee5f3b;
    font-size: 14px;
    float: right;
    i {
      margin-right: 8px;
    }
  }
  .rout {
    color: #afafaf;
    font-size: 14px;
    margin-bottom: 40px;

    span {
      color: #404041;
    }
  }
  // right
  .product_details_div {
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .basic-product-info {
      p {
        font-size: 34px;
        font-family: "Red Hat Display";
        font-weight: bold;
        color: #404041;
        line-height: 39px;
      }
      .seemore {
        font-size: 14px;
        font-family: "Red Hat Display";
        color: #404041;
        line-height: 1px;
        a {
          color: #ee5f3b;
          text-decoration: underline;
        }
        .rating_p {
          margin-left: 5px;
          margin-right: 20px;
        }
      }
      .instock {
        float: right;
        color: #53acaa;
        font-weight: bold;
        font-size: 16px;
        i {
          margin-right: 10px;
        }
      }
    }
    .price {
      color: #53acaa;
      font-size: 38px;
      margin-top: 5px;
      font-family: "Red Hat Display";
      font-weight: bold;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        justify-content: center;
      }
      del {
        color: #afafaf;
        font-size: 22px;
        font-weight: normal;
        margin-left: 20px;
      }
    }
    .delivery-div {
      height: 65px;
      display: flex;
      align-items: center;
      .flex {
        display: flex;
        align-items: end;
        margin-left: 12px;
        .free_del {
          font-size: 18px;
          color: #53acaa;
          font-family: "Red Hat Display";
          text-transform: capitalize;
          font-weight: bold;
          margin-bottom: 0px;
        }
        .same_day {
          font-size: 16px;
          margin-bottom: 0px;
          font-weight: 500;
        }
        span {
          color: #ee5f3b;
          text-decoration: underline;
          margin-left: 15px;
          font-weight: 500;

          i {
            margin-left: 5px;
          }
        }
      }
      img {
        height: auto;
      }
    }
    .view {
      position: relative;
      width: 200px;
      border-radius: 5px;
      background-color: transparent;
      border: 2px solid #b3b0b0;
      color: #404041;
      font-size: 12px;
      margin-top: 12px;
      height: 48px;
      font-weight: bold;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
       > .tooltipa {
          visibility: visible;
        }

      }
     > .tooltipa{
        visibility: hidden;

        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
       
        /* Position the tooltip text - see examples below! */
        bottom: 110%;
        position: absolute;
        z-index: 1;
        &::after {
          content: " ";
          position: absolute;
          top: 100%; /* At the bottom of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: black transparent transparent transparent;
        }
      }
      img {
        margin-right: 7px;
      }
    }
    .prod-deatils-buttons-wrapper {
      .counter_div {
        height: 45px;
        // width: 115px;
        padding: 0 30px;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #808080;
        border: 2px solid #b3b0b0 !important;
        border-radius: 5px;
        p {
          margin-top: 16px;
          font-size: 17px;
        }
        >button{
          outline: none;
          transition: all 0.3s ease-in-out;
          &:active{
            color : #53acaa;
            transform: scale(1.1);
          }
        }
        .minus {
          border: none;
          border-right: 1px solid #808080;
          position: absolute;
          left: 0px;
          color: #808080;
          background-color: transparent;
          height: 55%;
          width: 28%;
        }
        .plus {
          border: none;
          border-left: 1px solid #808080;
          color: #808080;
          position: absolute;
          right: 0px;
          background-color: transparent;
          height: 55%;
          width: 28%;

        }
      }
      .basket-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        padding-right: 0;
        @media (max-width: 550px) {
          padding-right: 0;
          padding-left: 0;
        }
        
        button {
          background-color: #53acaa;
          border: 2px solid #53acaa;
          border-radius: 5px;
          color: white;
          font-weight: bold;
          width: 100%;
          padding: 11px 0px;
          font-size: 12px;
        }
      }
      .save-btn-resp-wrapper {
        display: none;
      }
      .save-btn-pd{
        padding-right: 0;
      }
      .save-btn-wrapper {
        border: 2px solid #53acaa;
        background-color: white;
        height: 45px;
        width: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 13px;
        background: transparent;
        color: #53acaa;
        font-weight: bold;
        i {
          color: #53acaa;
          font-size: 22px;
          margin-right: 5px;
        }
      }
    }
    .gift {
      width: 100%;
      margin-top: 30px;
      border-radius: 8px;
      border: 2px solid #d6d2c1;
      padding: 4%;
      display: flex;
      align-items: center;
      p {
        font-size: 18px;
        font-weight: 400;
      }
      .round {
        position: relative;
      }
      .round label {
        background-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        cursor: pointer;
        height: 25px;
        margin-top: 4px;
        left: 0;
        position: absolute;
        top: 0;
        width: 25px;
      }

      .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 8px;
        left: 6px;
        opacity: 1;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 12px;
      }

      .round input[type="checkbox"] {
        visibility: hidden;
      }

      .round input[type="checkbox"]:checked + label {
        background-color: $bg_cyan;
        border-color: $bg_cyan;
      }

      .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
      .flex {
        display: flex;
        margin-left: 20px;
        img {
          height: 32px;
          padding: 0px 8px;
        }
        p {
          margin-top: 4px;
          margin-bottom: 1px;
        }
      }
    }
    .shop_at_amazon {
      display: none;
    }
    .collapse-wrapper {
      margin-top: 30px;
      width: 100%;
      h2 {
        font-size: 18px;
        width: 90%;
        color: #404041;
        font-weight: bold;
        float: left;
      }
      input[type="checkbox"] {
        display: none;
      }
      .wrap-collabsible {
        margin: 1.2rem 0;
        .lbl-toggle {
          height: 30px;
          width: 100%;
        }
      }
      .lbl-toggle {
        display: block;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.25s ease-out;
        -webkit-transition: all 0.25s ease-out;
        -moz-transition: all 0.25s ease-out;
        -ms-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
      }
      .lbl-toggle:hover {
        font-weight: bold;
      }
      .lbl-toggle::before {
        content: " ";
        display: inline-block;
        background-image: url("../../images/dropdown.png");
        vertical-align: middle;
        height: 6px;
        width: 10px;
        margin-right: 0.7rem;
        transition: transform 0.2s ease-out;
      }
      .toggle:checked + .lbl-toggle::before {
        transform: rotate(180deg) translateX(-3px);
      }
      .collapsible-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.25s ease-in-out;
      }
      .toggle:checked + .lbl-toggle + .collapsible-content {
        max-height: 1000px;
      }

      .toggle:checked + .lbl-toggle {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .collapsible-content .content-inner {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
    .product-overview-wrapper {
      margin-top: 50px;
      .heading {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 8px;
        color: #404041;
      }
      .para {
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        b{
          font-weight: 500 !important;
          text-align: start;

        }
        h2{
          font-weight: 500 !important;
          text-align: start;
          color: #000;

        }
        >div{
          // display: none;
        }
        >p{
          font-weight: 500 !important;
          >span{
            font-weight: 500 !important;
            text-align: start;
            

          }
          >b{

            display: none;
          }
        }
        >span{
          display: none;
        }
        &:last-child {
          display: block;
         }
        >div{
          >span{
            display: none;
          }
          &:last-child {
            display: block;
           }
          >div{
            >strong{
              font-weight: 500 !important;
    
            }
          }
          >strong{
            font-weight: 500 !important;
  
          }
        font-size: 16px !important;
        }
        >strong{
          font-weight: 500 !important;

        }
      }
      a {
        font-size: 16px;
        color: #ee5f3b;
        text-decoration: underline;
      }
    }
    .mobile-modal-wrapper {
      display: none;
    }
    .help-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .heading {
        color: #404041;
        font-size: 18px;
        font-family: "Red Hat Display";
        font-weight: bold;
      }
      button {
        height: 43px;
        width: 120px;
        font-size: 12px;
        font-weight: bold;
        color: #ee5f3b;
        border: 2px solid #ee5f3b;
        border-radius: 20px;
        background-color: transparent;
        img {
          margin-right: 10px;
        }
      }
    }
    ///
    .pro_specify_div {
      .sub_heading {
        color: #404041;
        font-size: 18px;
        font-family: "Red Hat Display";
        font-weight: bold;
      }
      table{
        margin-top: 20px;
      }
      tbody {
        color: #404041;
        
        font-size: 12px;
        font-weight: 500;
        border: 1px solid #f0f0f0;
        td {
          font-size: 14px;
          width: 50%;
          border: 1px solid #f0f0f0;
        }
      }
      p {
        font-family: "Red Hat Display";
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
@media (max-width: 991px) {
  .product_detail_wrapper {
    .product_details_div {
      .delivery-div {
        .flex {
          .free_del {
            font-size: 15px;
          }
          .same_day {
            font-size: 14px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .product_detail_wrapper {
    .product_details_div {
      .view {
        width: 100%;
        margin-bottom:4px;
      }
      .basic-product-info {
        display: none;
      }
      .delivery-div {
        margin-bottom: 25px;
        .flex {
          display: block;
          margin-left: 20px;
          span {
            margin-left: 0px;
          }
        }
      }
      .prod-deatils-buttons-wrapper {
        .save-btn-wrapper {
          display: none;
        }
        .save-btn-resp-wrapper {
          border: 2px solid #53acaa;
          height: 45px;
          width: 45px;
          // width: 80px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            color: #53acaa;
            font-size: 22px;
            justify-content: center;
            display: flex;
          }
        }
      }
      .shop_at_amazon {
        height: 120px;
        // border-radius: 9px;
        background-color: #e7e4d7;
        width: 100vw;
        display: flex;
        align-items: center;
        margin-top: 50px;
        @media (max-width: 768px) {
          margin-top: 20px;
        }
        margin-left:-15px;
        font-family: "Red Hat Display";
        img {
          height: 85%;
          padding: 15px;
        }
        .shop {
          padding-left: 8px;
          padding-top: 15px;
          h2 {
            font-weight: bold;
            font-size: 12px;
          }
          p {
            font-size: 9px;
          }
        }
      }
    }
  }
  .product-overview-wrapper {
    display: none;
  }
  .pro_specify_div {
    display: none;
  }
  .mobile-modal-wrapper {
    display: block !important;
    list-style: none;
    margin-top: 15px;
    li {
      font-size: 20px;
      font-weight: bold;
      padding: 15px 0px;
      border-bottom: 1px solid #f0f0f0;
      .flex-center {
        display: flex;
        align-items: end;
      }
      .div-wrapper {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 10px;
          width: 10px;
          background-repeat: no-repeat;
          background-image: url("../../images/dropdown.png");
        }
      }
    }
  }
}
.heading-text{
  font-size: 22px;
  font-weight: bold;
}

.upsell{
  border: 1.5px solid #F0F0F0;
  // transition: all 150ms ease;
  padding: 0.75rem;
  &:hover{

  border: 1.5px solid #53acaa;
  }
  // &:active{
  //   transform: scale(1.1);
  // }
  >img{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  @media (max-width: 550px) {
    max-width: 100px;
    min-width: 150px;
    padding: 1px;
  }
}
.selected{
  border: 1px solid #53acaa;

}
.highlight {
	display: inline-block;
	color: #fff;
  font-weight: 600;
	line-height: 1.5em;
  height: 30px;
	padding: 2px 10px 2px 15px;
	border-radius: 4px;
  position: relative;
  background-color: #ee5f3b;
}
.highlight:after {
	content: '';
	position: absolute;
	z-index: 11;
	right: -20px;
	top: 0;
	width: 5px;
	border: 15px solid #ee5f3b;
	border-right-color: transparent;
}

.wholesale{
  position: relative;
  &:hover {
    > .tooltipa {
       visibility: visible;
     }

   }
  > .tooltipa{
     visibility: hidden;

     width: 120px;
     background-color: black;
     color: #fff;
     text-align: center;
     padding: 5px 0;
     border-radius: 6px;
    
     /* Position the tooltip text - see examples below! */
     bottom: 110%;
     position: absolute;
     z-index: 1;
     &::after {
       content: " ";
       position: absolute;
       top: 100%; /* At the bottom of the tooltip */
       left: 50%;
       margin-left: -5px;
       border-width: 5px;
       border-style: solid;
       border-color: black transparent transparent transparent;
     }
   }
}
.upsellCont{
  position: relative;
  &:hover {
    > .tooltipa {
       visibility: visible;
     }

   }
  > .tooltipa{
     visibility: hidden;

     width: 130px;
     background-color: white;
     color: #002060;
     font-weight: 600;
     text-align: start;
     padding: 5px ;
     border-radius: 6px;
     font-size: 12px;
      border: 2px solid #53acaa;
     /* Position the tooltip text - see examples below! */
     bottom: 110%;
     left: 60%;
     position: absolute;
     z-index: 1;
     &::after {
       content: " ";
       position: absolute;
       top: 100%; /* At the bottom of the tooltip */
       left: 20%;
       margin-left: -5px;
       border-width: 5px;
       border-style: solid;
       border-color: #53acaa transparent transparent transparent;
     }
   }
}
