.dropdowns {
    border: 2px solid #959494;
    border-radius: 5px;
    background-color: transparent;
    // width: 150px;
    width: 100%;
    height: 45px;
    position: relative;
    outline: none;
    p {
        font-size: 11px;
        color: #797979;
        position: absolute;
        top: 9%;
        left: 8%;
        font-weight: 500;
    }
    small {
        color: #404041;
        font-size: 13px;
        position: absolute;
        top: 48%;
        left: 8%;
        font-weight: 500;
    }

    img {
        position: absolute;
        right: 5%;
        top: 47%;
    }
}

.dropdownn {
    position: relative;
    display: inline-block;
    background: transparent;
    outline: none;
    width: 100%;
    .dropdown-toggle::after {
        display: none !important;
    }
}
.bottomm{
    bottom: 0% !important;
    transform: translate(0, 100%);

}
.dropdown-contentt {
    display: block;

    position: absolute;
    bottom: 100%;
    left: 0px;
    background-color: transparent;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border: 2px solid #AFAFAF;
    border-radius: 8px;
    overflow: hidden;
    z-index: 2;
    background-color: white;
     a {
        color: black;
        font-size: 12px;
        padding: 10px 16px;
        display: block;
    }
    div{
        color: black;
        font-size: 12px;
        padding: 10px 16px;
        display: block;
    }
    a:hover {
        background-color: #ddd;
    }
    div:hover {
        background-color: #ddd;
    }
}



.menuSideBar{
    overflow-x: hidden;
    position: absolute;
    width: 300px;
    height: 100vh;
    background-color: white ;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    >div{ 
        color: black;
        padding: 0 10px;
    }
    z-index: 300000;
    .header{
        // border-bottom: ;
    }
    >.catego{
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        display: flex;
        flex-direction: column;
        >.sublist{
            transition: all 150ms ease-in-out;
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 100%;
            // background-color: red;
            background-color: white;
            display: flex;
            flex-direction: column;
            img{
                width: 23px;
            }
            >.items{
                height: 100px;
                overflow-y: scroll;
                flex: 1;
                >div{
                    padding: 10px 10px;
                    border-bottom: 1px solid #F0F0F0;
                    width: 100%;   
                    justify-content: flex-start;
                }

            }
            .subitem{
                border-bottom: 1px solid #F0F0F0;

            }
            
            .item {
                // border-bottom: 1px solid #F0F0F0;

            }
        }
        .off{
            left: 0% !important;

        }
        >.list{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1px;
            >div{
                display: flex;
                align-items: center;
                height: 40px;
                padding: 0px 0;
                border-bottom: 1px solid #F0F0F0;
                
            }
        }
      
        .active{
            color :#EE5F3B;
        }
        >.gifts{
                border: none;
                margin-top: auto;
                margin-bottom: 30px;
                color : #53ACAA;
                font-weight: bold;
                // width: ;
        }
    }
}

.arr{
    border-left: .5px solid #c3c3cb;
}