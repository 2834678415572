/* @tailwind base; */
@tailwind components;
@tailwind utilities;
* {
	outline: none;
	border: none;
}
.app{
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: "Red Hat Display";
	background-color: #fafafa;
  color : #404041
}

code {
  font-family: "Red Hat Display";
}
hr{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);}
    .splide__track{
      padding: 5px;
    }

    button:active{
      transform: scale(0.95);
    }