.header-wrapper {
  position: relative;

  .upper-header {
    background-color: #53acaa;
    height: 42px;
    align-items: center;
  }
  .flexstyle {
    display: flex;
    align-items: center;
  }
  .logo-div {
    display: flex;
    .menu-button {
      background: none;
      border: none;
      position: relative;
      &:active,
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-text {
    color: white;
    border-right: 1px solid white;
    margin-right: 10px;
    padding-right: 10px;
    .text1 {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }
  .text2 {
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
  .arrow-right {
    width: 15px;
    height: 10px;
    margin-left: 5px;
  }
  .text3 {
    color: white;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .header {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    .text {
      font-size: 16px;
      margin-bottom: 0px;
      font-weight: 500;
    }
  }
  .bg {
    background-color: white;
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .azoom-logo {
    height: 32px;
    margin-left: 25px;
  }
  .search-input {
    // border: 2px solid #afafaf;
    // border-radius: 5px;
    // padding: 2px 10px;
    width: 40%;
    position: relative;
    .searchDrop
    {
      position: absolute;
      top: 100%;
      z-index: 1000;
      background-color: white;
      border-radius: 0 0 10px 10px;
      // border: 2px solid #afafaf;
      height: 400px;
      left: 0;
      width: 100%;
      .item{
        transition: all 150ms ease-in-out;
        color : black;
        text-decoration: none;
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        padding: 0px 14px;        
        font-weight: 600;
        position: relative;
        cursor: pointer;
        // border-bottom: 2px solid #afafaf;
        &:hover{
          background-color: #f2f2f2;

        }
        &::after{
          content: "";
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translate(-50% , 0%);
          width: 100%;

          height: .5px;
          background-color: #a09f9f;
        }
        >img{
            width: 50px;
            max-height: 100%;
        }
        // border: 2px solid #afafaf;

      }
    }
    .search-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(-0% , -50%);
      border-right: none;
      border-radius: 2px;
      padding-right: 7px;
      height: 15px;
    }
    input {
      transition: all 300ms ease;
      border: 2px solid #afafaf;
      border-radius: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
       padding-left: 30px; 
        width: 100%;
        height: 100%;
        // padding-left: 20px;
      outline: none;
      &:focus{
        border: 2px solid #53ACAA;
  
        }
      font-weight: 500;
    }
  }

  .icons {
    display: flex;

  }
  .header-icon {
    width: 16px;
    height: 18px;
    // margin: 10px;
    @media (max-width: 768px) {
      width: 18px;
      height: 20px;
      margin: 0px;
    }
  }
  .cont{
    position: relative;
    margin: 10px;
    @media (max-width: 768px) {
      margin: 0px;
    }
  }
  .notification{
    display: block;
    background-color: #ee5f3b;
    width: 5px;
    height: 5px;
    border-radius: 9999px;
    position: absolute;
    right: 0;
    bottom: 1px;

  }
  .heart {
    &:hover{
      // >img{
        transition: all 400ms ease-in-out;
        transform: scale(1.1);
        // animation:  tilt-shaking 400ms ease-in-out ;
      // }
    }
  }
  .acc-icons {
    width: 22px;
    height: 20px;
    margin: 10px;
    @media (max-width: 768px) {
      width: 28px;
      height: 26px;
      margin: 0px;
      margin: 8px;
    }
  }
  .acc-text {
    font-size: 13px;
    font-weight: bold;
    color: black;
  }
  .acc-text-basket {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 8px;
    color: black;
  }
  .basket-item {
    position: relative;
    cursor: pointer;
    &:hover {
      > img {
        transform: scale(1.1);
        animation:  tilt-shaking 400ms ease ;
      }
      .quantity {
        transform: scale(0.8);
      }
    }
    display: flex;
    align-items: center;
    .quantity {
      transition:  all 300ms ease;
      position: absolute;
      background-color: #ee5f3b;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      top: 10%;
      right: 3px;
      color: wheat;
      font-size: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .price-text {
    font-size: 13px;
    font-weight: bold;
    margin-top: 15px;
  }
  .center {
    justify-content: center;
  }
  .nav-small {
    position: relative;
    .navbar {
      position: unset;

      .dropdown {
        display: inline-block;
        position: unset;
        z-index: 999;

        .dropdown-content {
          opacity: 0;
          visibility: hidden;
          width: 70vw;
          max-width: 1200px;
          position: absolute;
          left: 0;
          top: 60px;
          bottom: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          background-color: #f1f1f1;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 2;
          transition: all 0.3s ease;
          height: 50vh;
          
        }
        &:hover {
          .dropdown-content {
            visibility: visible;
            opacity: 1;
            top: 50px;
          }
        }
        &:not(:hover) {
          .dropdown-content {
            visibility: hidden;
            opacity: 0;
            top: 65px;
            .splide {
              opacity: 0 !important;
              visibility: hidden !important;
            }
          }
        }
      }
    }
  }
  .nav-item {
    margin-right: 25px;
    color: #707070;
    font-size: 16px;
    font-weight: 600;
  }
  .nav-item:hover {
    color: #ee5f3b;
    cursor: pointer;
    text-decoration: underline;
  }
  .active {
    color: #ee5f3b;
    font-size: 16px;
    font-weight: 600;
  }
  .active:hover {
    text-decoration: underline;
  }
}
@media (max-width: 1199px) {
  .header-wrapper {
    .search-input {
      input {
        width: 405px;
      }
    }
    .acc-text {
      font-size: 10px;
    }
    .acc-text-basket {
      font-size: 14px;
    }
  }
}
@media (min-width: 768px) {
  .header-wrapper {
    .search-input-resp {
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .header {
    .text {
      display: none;
    }
  }
  .header-wrapper {
    .header-text {
      margin-right: 0px;
      padding-right: 0px;
      .text1 {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }
    .mobile-hidden{
      display: none;
    }
    .upper-header {
      height: 55px;
    }
    .nav-small {
      display: none;
    }
    .flex-container {
      justify-content: center;
      .text3 {
        display: none;
      }
      .header-text {
        border: none;
      }
      .block {
        display: block;
      }
    }
    // .acc-text {
    //   display: none;
    // }
    .acc-text-basket {
      display: none;
    }
    .azoom-logo {
      height: 28px;
    }
    .search-input {
      display: none;
    }
    .search-input-resp {
      margin-bottom: 10px;
      margin-top: 10px;
      // padding: 7px 10px;
      position: relative;
      width: 100%;
      .search-icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(-0% , -50%);
        border-right: none;
        border-radius: 2px;
        padding-right: 7px;
        height: 15px;
      }
      .searchDrop
      {
        position: absolute;
        top: 100%;
        z-index: 1000;
        background-color: white;
        border-radius: 0 0 10px 10px;
        // border: 2px solid #afafaf;
        height: 60vh;
        left: 0;
        width: 100%;
        .item{
          transition: all 150ms ease-in-out;
          color : black;
          text-decoration: none;
          width: 100%;
          height: 70px;
          display: flex;
          align-items: center;
          padding: 0px 14px;        
          font-weight: 600;
          position: relative;
          cursor: pointer;
          // border-bottom: 2px solid #afafaf;
          &:hover{
            background-color: #f2f2f2;
  
          }
          &::after{
            content: "";
            position: absolute;
            bottom: -1px;
            left: 50%;
            transform: translate(-50% , 0%);
            width: 100%;
  
            height: .5px;
            background-color: #a09f9f;
          }
          >img{
              width: 50px;
              max-height: 100%;
          }
          // border: 2px solid #afafaf;
  
        }
      }
      input {
        transition: all 300ms ease;
      border: 2px solid #afafaf;
      border-radius: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
       padding-left: 30px; 
        width: 100%;
        height: 100%;
        // padding-left: 20px;
        &:focus{
          border: 2px solid #53ACAA;
    
          }
        font-weight: 500;
        
      }
    }
  }
  .header-wrapper {
    .shop-now-text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -15px;
    }
    .user-icon {
      width: 15px;
      height: 15px;
      margin: 10px;
    }
  }
}
@media (max-width: 991px) {
  .header-wrapper {
    .azoom-logo {
      height: 26px;
    }
    input {
      width: 225px;
    }
    .acc-text {
      // font-size: 7px;
      
    }
    .acc-text-basket {
      font-size: 12px;
    }
    .header {
      .text {
        font-size: 14px;
      }
    }
    .search-input {
      input {
        width: 210px;
      }
    }
  }
}

input {
    &:focus{
      border-color: red;
    }
}
@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.navpos{
  position: fixed;
  z-index: 999;
  top: 0;
  background-color: white !important;
  >div{
    background-color: white !important;
  }
}