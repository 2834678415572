
.test{
  max-width: 24%;
  width: 24%;
      height: 300px;

  @media (max-width: 768px) {
    max-width: 48%;
    width: 48%;
  }
}
.banner-div {

    &.outlet-banner {
      background-color: #53acaa;
    //   height: 300px;
      border-radius: 20px;
      border: 8px solid #f8f9fa;
      position: relative;
      cursor: pointer;

      .main-img {
        height: auto;
        width: 100%;

        position: relative;
        bottom:  0px;
        transition: bottom ease 0.5s;
    
      }
      &:hover {
        .main-img{

          bottom: 10px;
        }
      }
    }
    .discount-banner {
      height: auto;
      width: 100%;
      position: relative;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
    h6 {
      position: absolute;
      top: 12%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-weight: bold;
      font-size: 27px;
    }
    .outlet-text {
      position: absolute;
      top: 7%;
      // right: -19%;
      // transform: translate(-50%, -50%);
      right: 0;
      left: 0;
      margin: auto;
      max-width: 80%;
      text-align: center;
      color: white;
      font-size: 28px;
    }
    .discount-text {
      position: absolute;
      top: 19%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 14px;
    }
    .arrow {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 33%;
      left: calc(50% - 7px);
      transform: translate(-50%, -50%);
      font-size: 14px;
      transition: left ease 0.5s;
    }
    &:hover {
      .arrow {
        left: 51%;
      }
    }
  }

  .searchTest{
    max-width: 24%;
    width: 24%;
    @media (max-width: 768px) {
      max-width: 48%;
      width: 48%;
    }
  }
  .prod-description {
    @apply shadow-sm ;
    background-color: white;
    border: none;
    border-radius: 10px;
    // box-shadow: 0 3px 3px 5px rgba(0, 0, 0, 0.05);
    min-height: 395px;
    .img-div {
      position: relative;

      height: 213px;
      .tag_{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 30px;
        // background-color: #ee5f3b;
        border-radius: 0px 6px 0px 0;

        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: white ;
        // background-color: #ee5f3b;
      }
      .tag_new{
        position: absolute;
        bottom: 35px;
        left: 0;
        width: 60px;
        height: 30px;
        // background-color: #ee5f3b;
        border-radius: 0px 6px 0px 0;

        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: white ;
        // background-color: #ee5f3b;
      }

      .product-action {
        display: flex;
        position: absolute;
        text-decoration: none;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        justify-content: center;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        background-color: rgba(64, 64, 65, 0.8);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 600ms, visibility 600ms;
        transition: opacity 600ms, visibility 600ms;
        .checkbox {
          height: 35px;
          width: 35px;
          transition-duration: 0.5s;
        }
        h6 {
          transition-duration: 0.5s;
          color: white;
          font-size: 19px;
          font-weight: bold;
          margin-top: -21px;
        }
      }
      &:hover {
        .product-action {
          opacity: 1;
          visibility: visible;
          .basket_btn {
            width: 133px;
            height: 40px;
            background-color: var(--secondary_color);
            color: white;
            font-weight: bold;
            font-size: 14px;
            border-radius: 6px;
            margin-top: -8px;
            transition-duration: 0.5s;
          }
          .quickview_btn {
            font-weight: bold;
            height: 40px;
            width: 85px;
            font-size: 13px;
            padding: 5px;
            color: var(--para_color);
            border-radius: 6px;
            margin-top: -5px;
            transition-duration: 0.5s;
          }
        }
      }
      img {
        width: auto;
        height: 213px;
        margin-bottom: 12px;
        transition-duration: 0.5s;
          margin: 0 auto;
        @media (max-width: 768px) {
          height: 170px;
        }
      }
    }
    .prod-name {
      text-align: left;
      margin-left: 15px;
      .name {
        height: 43px;
        overflow: hidden;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-top: 16px;
        color: #404041;

        @media (max-width: 768px) {
          height: 42px;
        }
      }
      .writer {
        height: 20px;
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
    .prod-name-mega-menu {
      text-align: left;
      margin-left: 15px;
      .name {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0px;
        // margin-top: 16px;
      }
      .writer {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
    .price {
      color: #53acaa;
      font-size: 20px;
      text-align: left;
      margin-left: 15px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .free-delivery {
   
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      margin-left: 15px;
      margin-right: 15px;
      
      p {
        color: #53acaa;
        font-size: 11px;
        font-weight: 600;

        @media (max-width: 768px) {
          font-size: 10px;
        }
      }
      img {
        transition-duration: 150ms;
        &:hover{
          transform: scale(1.3);
        }
        width: 20px;
        height: 18px;
        @media (max-width: 768px) {
          margin-left: 10px;
        }
      }
    }
  }

  .loadImage{
    width: 100%;
    height: 100%;
    position: absolute;

    top: 0;
    left: 0;
    z-index: 9;
    animation: 1.5s shine linear infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px 5px 0px 0px;
    background-size: 200% 100%;
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }