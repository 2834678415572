.mega-menu-wrapper {
  .mega-menu-row {
    background: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 10px 15px 5px rgba(0, 0, 0, 0.1);
    
    .mega-col-9{
      position: relative;
      padding-bottom: 3rem;
      z-index: 10;

      .view_products{
        // position: absolute;
        // bottom: 2rem;
        // left: 2.5rem;
        border: 2px solid #404041;
        border-radius: 6px;
        width: 143px;
        height: 40px;
        text-align: center;
        font-weight: bold;
        font-size: 13px;
        color: #404041;  
        background-color: white;
        transition-duration: 0.5s;

        &:hover{
          background-color: #404041;
          color: white;
        }
      }

      .bg-shape{
        position: absolute;
        z-index: 0;
        @media (max-width: 768px) {
          display: none;
        }
      }

      .bg-shape-left{
        width: 50px;
        height: auto;
        left: 0;
        bottom: 20%;
      }

      .bg-shape-right{
        width: 40px;
        height: auto;
        right: 0;
        top: 20%;
      }

      .bg-shape-bottom{
        width: 300px;
        height: auto;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0%;
      }
    }

    .links-row {
      padding: 10px 25px;
      .quran {
        .sub_heading {
          color: #404041;
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 20px;
          position: relative;
          z-index: 10;
        }
        img {
          width: 18px;
          margin-right: 9px;
        }
        ul {
          list-style: none;
          font-size: 14px;
          color: #797979;
          line-height: 40px;
          font-weight: 500;
          z-index: 10;
          position: relative;
          .cyan {
            color: #53acaa;
          }
          .orange {
            color: #ee5f3b;
          }
          margin-bottom: 20px;
        }
        span {
          cursor: pointer;
          &:hover {
            color: #ee5f3b;
          }
        }
        li:hover {
          cursor: pointer;
          color: #ee5f3b;
        }
      }
    }

    .offers-wrapper {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: space-around;
      background-color: #f8f9fa;
      padding: 10px 25px;
      border-bottom-right-radius: 8px;
      .heading {
        color: #ee5f3b;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      
      .splide .splide__list .splide__slide {
        margin-top: -60px;
        position: relative;
      }

      .action-div {
        display: grid;
        place-items: center;
        .action-btn {
          background-color: #ee5f3b;
          color: white;
          font-weight: bold;
          font-size: 12px;
          border-radius: 8px;
          height: 43px;
          width: 65%;
          margin-bottom: 3%;
          border: none;
        }
      }
    }
    .all_pro {
      height: 43px;
      width: 172px;
      border: 2px solid #404041;
      color: #404041;
      background: transparent;
      font-size: 12px;
      font-weight: bold;
      border-radius: 8px;
      margin-top: 236px;
      background-color: transparent;
      transition-duration: 0.5s;
      &:hover {
        background-color: #404041;
        color: white;
      }
    }
  }

  @media screen and (min-width: 300px) and (max-width: 500px) {
    .all_pro {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.add-to-cart{
  // display: none;
}

.splide__pagination {
  bottom: -1.5em;
}