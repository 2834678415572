.reviews-wrapper {
  margin-top: 28px;
  .reviews-header{
    display: flex;
    .search-wrapper {
      flex: 1;
      .input-groups {
        height: 70px;
        width: 260px;
        font-size: 12px;
        position: relative;
        input {
          height: 50px;
          &:focus{
            outline: none;
            border: none;
          }
        }
    
        .input-group-append {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 50px;
          background: #53acaa;
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          img {
            width: 47%;
            height: 46%;
          }
        }
      }
    }
  }
  p {
    font-size: 11px;
    font-weight: 500;
    color: #797979;
  }


}
@media (max-width: 768px) {
  .reviews-wrapper {
    display: none;
  }
  .more-reviews{
    display: none;
  }
}
.review-show_more_filters{
  color: #ee5f3b;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}