.deliverynreturns-wrapper {
    width: 85%;
    // background-color: white;
    // margin: auto;
    // border-radius: 9px;
    // box-sizing: border-box;
    // margin-top: 5%;
    // padding: 15px 20px;
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
            text-align: left;
        }
        img {
            width: auto;
            height: 10px;
            cursor: pointer;
        }
    }
    .image-wrapper {
        padding: 20px 0px;
        width: 40%;
        display: flex;
        justify-content: center;
        img {
            width: auto;
            height: 160px;
        }
    }
    .product-info-wrapper {
        padding: 30px;
        .basic-product-info {
            p {
                font-size: 16px;
                font-family: "Red Hat Display";
                font-weight: bold;
                color: #404041;
                margin-bottom: 8px;
            }
            .author {
                font-size: 14px;
                font-weight: 500;
            }
            .price {
                color: #53acaa;
                font-size: 22px;
                font-family: "Red Hat Display";
                font-weight: bold;
                display: flex;
                align-items: center;
            }
        }
    }
    .addtobasket-btn {
        background-color: #53acaa;
        margin-top: 20px;
        text-align: center;
        height: 40px;
        width: 100%;
        border-radius: 5px;
        border: 2px solid #53acaa;
        color: white;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
    }
    .delivery-div {
        height: 65px;
        display: flex;
        align-items: center;
        .free_del {
            font-size: 18px;
            color: #53acaa;
            font-family: "Red Hat Display";
            text-transform: capitalize;
            font-weight: bold;
            margin-bottom: 0px;
        }
        .image-wrapper {
            padding: 18px;
            img {
                height: auto;
            }
        }
    }
    .dispatchment-wrapper{
        p{
            font-size: 16px;
            color:#404041;
            margin-bottom: 8px;
            img{
                height: auto;
                margin-left: 7px;
                width: 20px;
            }
        }
        i{
            margin-left: 7px;
        }
        span{
            color:#EE5F3B;
            text-decoration: underline;
            margin-left: 7px;
        }
        h4{
            font-size: 14px;
            color:#404041;
            font-weight: bold;
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }
    .estimated-delivery{
        margin-top: 25px;
        h4{
            font-size: 16px;
            font-weight: bold;
        }
        p{
            font-size: 16px;
            font-weight: 500;
        }
    }
    .delivery-feature{
        margin-top: 25px;
        .flex{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
          }
          .img-wrapper{
            width: 30%;
            .delivery-img{
                height: 40px;
                width: auto;
            }
            .moneyback-img{
                height:auto;
                width: auto;
            }
          }
        h3{
            font-size: 16px;
        }
        p{
            color:#AFAFAF;
            font-size: 12px;
        }
        span{
            font-size: 12px;
            color:#EE5F3B;
            text-decoration: underline;

        }
    }
}
