.footer-wrapper {
	background-color: white;
	padding-top: 15px;
	.footer-opts {
		p {
			overflow: hidden;
			text-align: left;
		}
		p:after {
			background-color: #f0f0f0;
			content: "";
			display: inline-block;
			height: 1px;
			position: relative;
			vertical-align: middle;
			width: 70%;
		}
		p:before {
			right: 0.5em;
			margin-left: -50%;
		}
		p:after {
			left: 0.5em;
			margin-right: -50%;
		}
	}
	ul {
		list-style: none;
		padding-left: 5px;
		li {
			&:first-child {
				margin-bottom: 10px;
				font-size: 20px;
			}
			&.item {
				a {
					color: #afafaf;
					font-weight: 500;
				}
				font-size: 16px;
				margin-bottom: 8px;
				&.support {
					color: #404041;
					font-weight: 500;
				}
			}
		}
		.call-us-btn-li {
			margin-bottom: 15px;
			margin-top: 10px;
		}
		.action-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			padding: 8px 15px;
			border-radius: 20px;
			font-size: 13px;
			font-weight: bold;
			transition-duration: 0.5s;
			&.call {
				background-color: transparent;
				border: 2px solid #ee5f3b;
				color: #ee5f3b;
				box-shadow: none;
				transition-duration: 0.5s;
				&:hover {
					background-color: #ee5f3b;
					color: white;
				}
			}
			i.far.fa-clock {
				font-size: 17px;
			}
			i.fa.fa-phone-volume {
				transform: rotate(-45deg);
				font-size: 18px;
			}
			&.service {
				background-color: transparent;
				color: #ee5f3b;
				border: 2px solid #ee5f3b;
				box-shadow: none;
				&:hover {
					background-color: #ee5f3b;
					color: white;
				}
			}
		}
	}
	.flex {
		display: flex;
	}
	.link {
		color: #ee5f3b;
		text-decoration: underline;
		font-weight: 500;
		// font-family: $font-family;
	}
	.trust-icon {
		height: 35px;
		width: 130px;
	}
	.opts-text {
		color: #afafaf;
		font-size: 14px;
		&.brand-title {
			margin-left: 10px;
		}
	}

	.brand-icon {
		height: 18px;
		margin: 10px;
		width: auto;
		&.pay-maestro {
			height: 22px !important;
		}
	}
	.delvery-partners-icon {
		width: auto;
		margin: 5px 7px;
		&.dhl {
			height: 11px;
		}
		&.ups {
			height: 27px;
		}
		&.panther-xdp-dpd {
			height: 23px;
		}
		&.bjs {
			height: 19px;
		}
		&.parcel {
			height: 30px;
		}
		&.hermes {
			height: 14px;
		}
		&.dx {
			height: 18px;
		}
		&.arrowxl {
			height: 22px;
		}
	}
	.social-icon {
		width: auto;
		height: 15px;
		margin-right: 30px;
	}
	.footer-text {
		color: #404041;
		font-size: 13px;
	}
	.terms-border {
		height: 13px;
		display: flex;
		align-items: center;
		border-right: 1px solid #404041;
		cursor: pointer;
		a {
			color: #ee5f3b;
			text-decoration: underline;
			font-size: 13px;
			margin-right: 10px;
		}
		&:last-child {
			border-right: none;
		}
	}
	.cookies-div {
		position: fixed;
    bottom: 0px;
    right: 0;
    z-index: 300;
		background-color: #f0f0f0;
		padding: 16px 7px;
		// font-family: $font-family;
		.text-div {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.text {
			color: #404041;
			font-size: 11px;
			margin-bottom: 0px;
		}
		.privacy-link {
			text-decoration: underline;
			font-weight: bold;
			color: #404041;
			margin-left: 2px;
		}
		.display {
			display: flex;
			align-items: center;
			gap: 3px;
		}
		.cookie-link {
			font-size: 12px;
			color: #afafaf;
			text-decoration: underline;
			font-weight: bold;
			margin-right: 10px;
		}
		.cookies-btn {

			background-color: transparent;
			border: 1.5px solid black;
			border-radius: 5px;
			color: black;
			font-size: 11px;
			font-weight: 600;
			padding: 9px 25px;
			outline: none;
			transition-duration: 0.5s;
			&:hover {
				background-color: black;
				color: white;
			}
		}
	}
}
@media (max-width: 768px) {
	.footer-wrapper {
		.cookies-div {
			.text-div {
				display: block;
				.display {
					flex-direction: column-reverse;
					margin-top: 12px;
				}
			}
			.text {
				text-align: center;
			}
			.cookies-btn {
				width: 50%;
			}
			.cookie-link {
				margin-top: 5px;

			}
		}
		ul {
			.item {
				color: #afafaf;
				font-size: 14px;
			}
		}
		.footer-text {
			font-size: 10px;
		}
		.link {
			font-size: 14px;
		}
		.link-terms {
			font-size: 12px;
		}
		.margin {
			margin-top: 20px;
		}
	}
}
