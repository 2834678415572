
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: ghostwhite;
}

.bm-burger-bars-hover {
  background: #a90000;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 18px !important;
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: auto;
  max-width: 400px;
  min-width: 300px;
  overflow-y: hidden !important;
}

.bm-menu {
  background: white;
  padding: 0px;
  font-size: 1.15em;
  overflow-y: hidden !important;
}

.bm-menu-wrap.bg-primary {
  background: #fafafa !important;
  width: 350px !important;
}

.bm-menu-wrap.bg-primary > .bm-menu{
  background: #fafafa !important;
  overflow-y: auto !important;
}

.order-sign{
  margin-bottom: 0;
  margin-left: 10px;
  color: red;
  margin-top: 3px;
}


.bm-cross-button{
  left: 1.5rem;
}

.bm-morph-shape {
  fill: ghostwhite;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0px;
  height: auto !important;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  // display: none;
  background: rgba(0, 0, 0, 0.3);
}

.side_bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .nav {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .logo {
      height: 35px;
      width: 66%;
    }
  }
  ul {
    width: 100%;
    padding-inline-start: 0px;
    .side_menu_header {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: -23px;
      svg {
        cursor: pointer;
        height: 73px;
        width: 200px;
      }
      .closeIcon {
        font-weight: 500;
        margin-bottom: 0;
        margin-right: 20px;
        cursor: pointer;
        font-size: 20px;
        color: #404041;
        ;
      }
    }
    hr{
      @media (max-width: 768px) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

      }
    }
    li {
      display: flex;
      align-items: center;
      padding: 12px 20px;

      @media (max-width: 768px) {
        padding: 8px 20px !important;

      }

      img {
        margin-right: 12px;
        width: 20px;
        height: auto;
      }
      .ptsIcon{
        width: 45px;
        height: auto;

      }
      h3 {
        font-size: 12px;
        font-weight: bold;
        color: #404041;
        margin-bottom: 0px;
        span {
          font-size: 10px;
          color: #404041;
        }
        &.account {
          display: flex;
          flex-direction: column;
        }
      }
      .sale{
        color: #ee5f3b !important;
      }
    }
    li {
      padding: 12px 20px;
      a {
        display: flex;
        align-items: center;
        img {
          margin-right: 12px;
        }
      }
    }
  }

  .action-btn-wrapper {
    display: flex;
    justify-content: center;
    .signout_btn {
      border: 2px solid #ee5f3b;
      color: #ee5f3b;
      font-weight: bold;
      border-radius: 8px;
      background-color: transparent;
      height: 45px;
      width: 70%;
      font-size: 12px;
      position: absolute;
      bottom: 30px;
      // margin-bottom: 30px;
      // margin-top: 120px;
      &:hover {
        background-color: #ee5f3b;
        color: white;
        cursor: pointer;
      }
    }
  }
}

.sidebar-main-content ul li{
  border-bottom: 2px solid rgba(0,0,0,.1);
}

.section-seprator{
  @media (max-width: 768px) {
    border-top: 1px solid white !important;
    margin-bottom: 0;
    background-color: white !important;

  }
  
}
