.btn_div {
	display: inline;
	margin-left: 10px;
	float: right;
	.dropdown {
		position: relative;
		display: inline-block;
		background: transparent;
		outline: none;
		.dropdown-toggle::after {
			display: none !important;
		}
	}
	.dropdown-contentt {
		display: block;

		position: absolute;
		bottom: 100%;
		left: 0px;
		background-color: transparent;
		width: 100%;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		border: 2px solid #AFAFAF;
		border-radius: 8px;
		overflow: hidden;
		z-index: 2;
		background-color: white;
		 a {
			color: black;
			font-size: 12px;
			padding: 10px 16px;
			display: block;
		}
		div{
			color: black;
			font-size: 12px;
			padding: 10px 16px;
			display: block;
		}
		a:hover {
			background-color: #ddd;
		}
		div:hover {
			background-color: #ddd;
		}
	}
	.dropdown:hover .dropdown-content {
		// display: block;
	}
	.show img {
		transform: rotate(180deg);
	}
	.dropdowns {
		border: 2px solid #AFAFAF;
		border-radius: 5px;
		background-color: transparent;
		width: 179px;
		height: 45px;
		position: relative;
        outline: none;

		p {
			font-size: 11px;
			color: #797979;
			position: absolute;
			top: 9%;
			left: 8%;
			font-weight: 500;
		}
		small {
			color: #404041;
			font-size: 13px;
			position: absolute;
			top: 48%;
			left: 8%;
			font-weight: 500;
		}

		img {
			position: absolute;
			right: 5%;
			top: 47%;
		}
	}

	.add_btn {
		background-color: #797979;
		color: white;
		height: 43px;
		border-radius: 6px;
		img {
			margin-right: 10px;
		}
		b {
			font-size: 12px;
		}
	}
}


.mobile-none{
	@media (max-width: 767px) {
		display: none;
	}
}