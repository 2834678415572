.headingText {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.rollingText {
  color: #404041;
  font-size: 14px;
}

.alignItems {
  display: flex;
  flex-direction: row;
}
.linkColor{
  color: #53acaa;
  text-decoration: underline;
}

.imgSize {
  width: 18px;
  height: 18px;
}