.app-checkbox {
  @apply rounded-full cursor-pointer appearance-none m-0 grid place-content-center;

  background-color: #DCDEE6;
  width: 18px;
  height: 18px;
  transform: translateY(-0.075em);
  padding: 3px;
  transition: background-color .2s ease-in-out;

  &:disabled {
    @apply cursor-default;
  }

  &:checked {
    @apply bg-tertiary;
  }

  &--primary {
    &:checked {
      @apply bg-primary;
    }
  }

  &::before {
    @apply absolute border-white;

    content: '';
    top: 3px;
    left: 6px;
    width: 6px;
    height: 10px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:checked::before {
    //@apply border-light;

    content: '';
    border-width: 0 2px 2px 0;
  }

  &--sm {
    width: 15px;
    height: 15px;

    &::before {
      left: 5px;
      width: 5px;
      height: 7px;
      border-width: 0 2px 2px 0;
    }
  }
}


.box-search {
  position: relative;
  width: 100%;
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  input {
    position: relative;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 2px solid #afafaf;
    font-size: 15px;
    padding-left: 10px;
    background: transparent;
    z-index: 2;
    transition: 0.25s;
  }
  span {
    display: inline-block;
    position: absolute;
    left: 0;
    color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: 0.25s;
  }
  a {
    position: absolute;
    top: 25%;
    right: 3%;
    text-decoration: underline;
  }

  input + span {
    top: 30%;
    left: 6%;
  }
  input:focus + span {
    top: 4%;
    font-size: 12px;
    color : #53acaa;

  }
  input:focus{
    border: 2px solid #53acaa;
  }
}

.focusedTrue{
  >span{
     top: 4%!important;
    font-size: 12px;
    
  }
}
.round {
  position: relative;
}
.agree-terms{
  text-align: left;
  margin-left: 2.2rem;
}
.round label {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  margin-top: 4px;
  left: 0;
  position: absolute;
  // top: 20px;
  width: 25px;
}
.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 8px;
  left: 6px;
  opacity: 1;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #53acaa;
  border-color: #53acaa;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}