.product_images_wrapper {
  .basic-product-info{
    display: none;
  }
  .big-image-wrapper {
    min-width: 100%;
    height: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    .hover{

      &:hover {
        cursor: zoom-in;
        // background-size: 140%;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    .main_image {
      content: "";
      width: 100%;
      height: 90%;
      // background-image: url("../../images/badge_muslim-world-league.png");
      background-size: contain;
      background-repeat: no-repeat;

      position: relative;
      
    }
  }
  .product_images_slider {
    width: 100%;
    height: 100px;
    margin-top: 40px;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    @media (max-width: 600px) {
      // height: 15px;
      display: none;
    }
    .slider-img {
      padding: 5px;
      width: 113px;
      height: 113px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70%;
        max-height: 100%;
        max-width: 99%;
      }
      @media (max-width: 600px) {
    width: 50px;
    height: 12px;
    border-radius: 5px;
        background-color: #DDDDDD;
        >img{
          display: none;
        }

}
    }
    .splide__arrow--prev {
      left: 0em;
    }
    .splide__arrow--next {
      right: 0em;
    }
    .splide__arrow {
      background: none;
    }
  }
  .shop_at_amazon {
    height: 100px;
    border-radius: 9px;
    background-color: #e7e4d7;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 50px;
    font-family: "Red Hat Display";
    img {
      height: 85%;
      padding: 15px;
    }
    .shop {
      padding-left: 10px;
      padding-top: 17px;
      h2 {
        font-weight: bold;
        font-size: 14px;
      }
      p {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}
@media (max-width: 992px) {
  .product_images_wrapper {
    .big-image-wrapper {
      .main_image {
        height: 65%;
      }
    }
    .shop_at_amazon img {
      height: 50%;
    }
  }
}
@media (max-width: 768px) {
  .product_images_wrapper {
    .basic-product-info {
      display: block !important;
      p {
        font-size: 34px;
        font-family: "Red Hat Display";
        font-weight: bold;
        color: #404041;
        line-height: 39px;
      }
      .seemore {
        font-size: 14px;
        font-family: "Red Hat Display";
        color: #404041;
        line-height: 1.2rem;
        a {
          color: #ee5f3b;
          text-decoration: underline;
        }
        .rating_p {
          margin-left: 5px;
          margin-right: 20px;
        }
      }
      .instock {
        float: right;
        color: #53acaa;
        font-weight: bold;
        font-size: 16px;
        line-height: inherit;
        i {
          margin-right: 10px;
        }
      }
    }
    .big-image-wrapper {
      height: 325px;
      justify-content: flex-start;
      .main_image {
        height: 105%;
      }
    }
    .shop_at_amazon {
      display: none;
    }
  }
}

#product_images{
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
  .splide__pagination{
    position: absolute;
    bottom: -30px;
    width: 90%;
    display: flex;
    gap: 20px;
    >li{
      >button{
        width: 35px;
        height: 10px;
        border-radius: 5px;
        border-color: #53acaa;
      }
      .is-active{
        background-color: #53acaa;

      }
    }
  }
}

.tag_{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 40px;
  z-index: 299;
  // background-color: #ee5f3b;
  border-radius: 0px 6px 0px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white ;

}
.tag_sale {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 40px;
  // background-color: #ee5f3b;
  border-radius: 0px 6px 0px 0;
  z-index: 299;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white ;

}

* {box-sizing: border-box;}

.left {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom{
  width: 150% !important;
  height: 150%!important;
    left: 50%;
  top: 50%;
}
.left > img {
  height: auto;
  height: 100%;
}

.right {
  position: relative;
  overflow: hidden;
}
