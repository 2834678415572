.filters-wrapper {
  background-color: white;
  border-radius: 10px;
  height: auto;
  padding: 15px;
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .show_more_filters{
    color: #ee5f3b;
    font-size: 16px;
    text-decoration: underline;
    font-weight: 500;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .inp_div {
    width: 100%;
    margin-top: 20px;
    .flex {
      display: flex;
      align-items: flex-end;
      // justify-content: start !important;
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 30px;
      }
      .switch input {
        display: none;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #afafaf;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 23px;
        width: 23px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: $bg_cyan;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px $bg_cyan;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(30px);
        -ms-transform: translateX(30px);
        transform: translateX(30px);
      }
      .slider.round {
        border-radius: 34px;
      }
      .slider.round:before {
        border-radius: 50%;
      }
    }
    .delivery-text {
      font-family: var(--primary_font);
      font-size: 14px;
      color: var(--secondary_color);
      padding-left: 8px;
      font-weight: 500;
    }
    .loading-box{
      height: 20px;
    }

    .search-input-wrapper {
      display: none;
      .full-width {
        width: 100%;
      }
      .box-search {
        position: relative;
        width: 100%;
        input {
          position: relative;
          border: none;
          outline: none;
          height: 45px;
          width: 100%;
          font-size: 22px;
          padding-bottom: 8px;
          padding-top: 12px;
          box-sizing: border-box;
          border-radius: 8px 0px 0px 8px;
          border: 2px solid #afafaf;
          font-size: 15px;
          padding-left: 10px;
          background: transparent;
          z-index: 2;
          transition: 0.25s;
        }
        span {
          display: inline-block;
          position: absolute;
          left: 0;
          color: rgba(0, 0, 0, 0.5);
          z-index: 1;
          transition: 0.25s;
        }
        input + span {
          top: 25%;
          left: 5%;
        }
        input:focus + span {
          top: 0%;
          font-size: 12px;
        }
      }
      .box {
        background-color: var(--secondary_color);
        color: white;
        width: 20%;
        border-radius: 0px 8px 8px 0px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .price-input-wrapper {
    display: flex;
    .min-price-input {
      position: relative;
      width: 100%;
      input {
        position: relative;
        outline: none;
        height: 45px;
        width: 95%;
        padding: 8px 5px;
        border-radius: 8px;
        border: 1px solid #afafaf;
        font-size: 15px;
        background: transparent;
        z-index: 2;
        transition: 0.25s;
        &::placeholder {
          // color: #2196f3;
          text-align: center;
          font-size: 16px;
        }
      }
      span {
        display: inline-block;
        position: absolute;
        left: 0;
        color: rgba(0, 0, 0, 0.5);
        z-index: 1;
        transition: 0.25s;
      }
      input + span {
        top: 25%;
        left: 7%;
      }
      input:focus + span {
        top: 0%;
        font-size: 12px;
        left: 5%;
      }
      .focusedTrue {
        top: 0%;
        font-size: 12px;
        left: 5%;
      }
    }

    .max-price-input {
      position: relative;
      width: 100%;
      input {
        position: relative;
        outline: none;
        height: 45px;
        width: 95%;
        padding: 8px 5px;
        border-radius: 8px;
        border: 1px solid #afafaf;
        font-size: 15px;
        background: transparent;
        z-index: 2;
        transition: 0.25s;
        &::placeholder {
          // color: #2196f3;
          text-align: center;
          font-size: 16px;
        }
      }
      span {
        display: inline-block;
        position: absolute;
        left: 0;
        color: rgba(0, 0, 0, 0.5);
        z-index: 1;
        transition: 0.25s;
      }
      input + span {
        top: 24%;
        left: 7%;
      }
      input:focus + span {
        top: 0%;
        left: 5%;
        font-size: 12px;
      }
      .focusedTrue {
        top: 0%;
        font-size: 12px;
        left: 5%;
      }
    }
  }
  .filters {
    margin-top: 30px;
    width: 100%;

    h2 {
      font-size: 17px;
      width: 90%;
      color: #404041;
      font-weight: bold;
      font-family: var(--primary_font);
      background-color: white;
      float: left;
    }
    // input[type="checkbox"] {
    //   display: none;
    // }
    .wrap-collabsible {
      margin: 1.2rem 0;
      .lbl-toggle {
        height: 30px;
        width: 100%;
      }
    }
    .lbl-toggle {
      display: block;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.25s ease-out;
    }
    .lbl-toggle:hover {
      font-weight: bold;
    }
    .lbl-toggle::before {
      content: " ";
      display: inline-block;
      background-image: url("../../images/dropdown.png");
      height: 101px;
      vertical-align: middle;
      height: 6px;
      width: 10px;
      margin-right: 0.7rem;
      transition: transform 0.2s ease-out;
    }
    .toggle:checked + .lbl-toggle::before {
      transform: rotate(180deg) translateX(-3px);
    }
    .collapsible-content {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out;
    }
    .toggle:checked + .lbl-toggle + .collapsible-content {
      max-height: 350px;
    }
    .toggle:checked + .lbl-toggle + .collapsible-content-s {
      max-height: 100px;
    }
    .toggle:checked + .lbl-toggle {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .collapsible-content .content-inner {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      &.categories {
        max-height: 235px;
        overflow: auto;
        padding: 0.5rem 0rem;
        padding-bottom: 1rem;
        ul {
          margin-bottom: 0;
          li {
            // margin-left: 15px;
            &.top_li {
              margin-left: 0px;
            }
          }
        }
        .disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
      .round {
        position: relative;
      }
      .round label {
        background-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        cursor: pointer;
        height: 25px;
        margin-top: 4px;
        left: 0;
        position: absolute;
        top: 0;
        width: 25px;
      }

      .round .filter-checked{
        color:#ee5f3b;
      }

      .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 8px;
        left: 6px;
        opacity: 1;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 12px;
      }

      // .round input[type="checkbox"] {
      //   visibility: hidden;
      // }

      // .round input[type="checkbox"]:checked + label {
      //   background-color: $bg_cyan;
      //   border-color: $bg_cyan;
      // }

      // .round input[type="checkbox"]:checked + label:after {
      //   opacity: 1;
      // }
      ul {
        list-style: none;
        font-size: 14px;
        font-family: var(--primary_font);
        color: #404041;
        line-height: 33px;
      }

      .p_range {
        height: 59px;
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          // display: inline-block;
          padding: 5px;
          width: 49%;
          height: 100%;
          border: 1px solid var(--para_color);
          border-radius: 7px;
        }

        p {
          color: var(--para_color);
          font-size: 11px;
        }
        input {
          height: 20px;
          width: 100%;
          border: none;
        }
      }
    }
    .collapsible-content p {
      margin-bottom: 0;
      padding-left: 35px !important;
      font-weight: 500;
    }
    .more_filters {
      color: var(--primary_color);
      text-decoration: underline;
      font-size: 14px;
      margin-bottom: 170px;
    }
    .offers {
      color: var(--primary_color);
    }
    .new_arrivals {
      color: var(--secondary_color);
    }
  }
}
@media (max-width: 1200px) {
  .filters-wrapper {
    overflow-y: scroll !important;
    max-height: 100vh;
    .filters {
      h2 {
        width: 88%;
      }
    }
    .inp_div {
      .delivery-text {
        font-size: 12px;
      }
    }
  }
}
