@import './filters';
@import './quickViewModal';
@import './productViewImages';

:root {
  --primary_color: #ee5f3b;
  --secondary_color: #53acaa;
  --primary_font: 'Red Hat Display';

  --para_color: #797979;
}
.products-page-wrapper {
  padding: 35px 0px;
  .row {
    //left part
    .flex {
      display: flex;
      align-items: center;
      .pagination {
        width: 80%;
        justify-content: center;
        display: flex;
        .arrow-div {
          margin: 0px 5px;
          border: 1px solid #dddddd;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .arrow-div:hover {
          background-color: #dddddd;
        }
        a {
          color: #404041;
          font-weight: bold;
          float: left;
    
          text-decoration: none;
        }
        .active-no{
          color: #53acaa;
          text-decoration: underline;
        }
      }
      .items-per-page-wrapper {
        width: 20%;
        text-align: end;
        form {
          select {
            background-color: transparent;
            border: 1px solid #afafaf;
            border-radius: 5px;
            padding: 10px 15px 10px 3px;
            option {
              font-size: 14px;
              color: #797979;
            }
          }
        }
      }
    }
    .dropdown-wrapper {
      text-align: right;
      .dropdown {
        background-color: transparent;
        border: 1px solid #afafaf;
        border-radius: 4px;
        width: 195px;
        padding: 10px 5px;
        font-size: 14px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
    ///right part
    pre {
      color: #cccccc;
      font-size: 14px;
      font-family: var(--primary_font);
      margin-top: 8px;
    }
    .main-heading {
      font-weight: bold;
      font-size: 30px;
      font-family: var(--primary_font);
      color: #404041;
    }
    .sec-desc {
      font-size: 13px;
      color: #404041;
      font-family: var(--primary_font);
      font-weight: 500;
      small{
        margin-top: 10px;
        font-weight: 500;
        color: #afafaf;
      }
    }
    .results-filter-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: #afafaf;
        font-size: 16px;
      }
      .filters-btn {
        color: $bg_cyan;
        background-color: transparent;
        border: 2px solid $bg_cyan;
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        img {
          padding: 5px;
          padding-right: 8px;
          height: 25px;
        }
      }
    }
    .recommended {
      border: 1px solid #afafaf;
      border-radius: 8px;
      height: 50px;
      width: 167px;
      padding-left: 8px;
      width: 169px;
      position: relative;
      pre {
        position: absolute;
        top: -16px;
        font-size: 11px;
        color: #afafaf;
      }
      p {
        position: absolute;
        top: 22px;
        font-size: 13px;
      }
      i {
        position: absolute;
        top: 21px;
        right: 14px;
        color: #afafaf;
        font-size: 13px;
      }
    }
    .products-wrapper {
      .product-card {
        margin-top: 10px;
        margin-bottom: 45px;
        .card {
          border: 8px solid #f8f9fa;
          border-bottom: 8px solid #f0f1f1;
          // box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.05);
          background-color: white;
          height: fit-content;
          border-radius: 20px;
          padding: 0px;
          overflow: hidden;
          .img_div {
            height: 250px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .favicon-mobile {
              display: none;
            }
            .viewicon-mobile {
              display: none;
            }
            img {
              // width: 100%;
              max-height: 250px;
            }
            .product-action {
              display: flex;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              background-color: rgba(64, 64, 65, 0.8);
              opacity: 0;
              visibility: hidden;
              text-decoration: none;
              -webkit-transition: opacity 600ms, visibility 600ms;
              transition: opacity 600ms, visibility 600ms;
            }
          }
          .img_div:hover {
            .product-action {
              opacity: 1;
              visibility: visible;
              .basket_btn {
                width: 133px;
                height: 40px;
                background-color: var(--secondary_color);
                color: white;
                font-weight: bold;
                font-size: 14px;
                border-radius: 6px;
              }
              .quickview_btn {
                font-weight: bold;
                height: 40px;
                width: 85px;
                font-size: 13px;
                padding: 5px;
                color: var(--para_color);
                border-radius: 6px;
              }
            }
          }
          .product_name {
            font-family: var(--primary_font);
            font-size: 16px;
            margin-bottom: 0px;
            height: 53px;
            overflow: hidden;
            font-weight: 500;
            @media (max-width: 768px) {
              font-size: 13px;
            height: 41px;

            }
          }
          .text-muted {
            color: #6c757d !important;
            font-size: 14px;
            font-weight: 500;
            @media (max-width: 768px) {
              font-size: 13px;
            }
          }
          .price {
            color: var(--secondary_color);
            font-size: 22px;
            font-family: var(--primary_font);
            font-weight: bold;
          }
          pre {
            font-family: var(--primary_font);
            font-size: 14px;
            font-weight: bold;
            color: var(--secondary_color);
          }
          .fav-icon {
            height: 20px;
            cursor: pointer;
          }
        }
      }
      //cards end
    }
    .page_no {
      margin: auto;
      list-style: none;
      color: #404041;
      width: 100%;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        display: inline;
        margin-left: 2%;
        margin-right: 2%;
      }
      .fas {
        padding: 9px;
        // height:30px;
        // width:30px;
        border-radius: 50%;
        border: 1px solid #afafaf;
      }
      .fa-arrow-left {
        background: #afafaf;
      }
      a {
        color: #404041;
      }
      a:hover {
        color: rgb(8, 22, 223);
        cursor: pointer;
      }
    }
    .recently-viewed-heading {
      font-family: var(--primary_font);
      font-size: 21px;
      color: #404041;
      margin-top: 65px;
      font-weight: bold;
      text-align: center;
      justify-content: center;
    }
    .recently-viewed-items {
      margin-top: 40px;
      margin-bottom: 60px;
      // gap: 10px;
      .viewed-product-card {
        border: 5px solid #f7f7f9;
        padding: 0px;
        box-sizing: border-box;
        background: white;
        border-radius: 20px;
        .img-div {
          height: 230px;
          background-color:#FCFCFC;
          padding: 25px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 120px;
          }
        }
        .content-div{
          padding: 15px;
        }
        pre {
          font-family: var(--primary_font);
          font-weight: bold;
          color: var(--secondary_color);
          font-size: 22px;
          margin-top: 10px;
          margin-bottom: 0px;
        }
        p {
          margin-top: -13px;
        }
        .checked {
          color: black;
        }

        .unchecked {
          color: transparent;
          -webkit-text-fill-color: white;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: black;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .products-page-wrapper {
    .row {
      .recently-viewed-items {
        .viewed-product-card {
          img {
            height: 120px;
            width: 135px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .items-dropdown {
    display: none;
  }
  .recently-viewed-heading {
    display: none;
  }
  .recently-viewed-items {
    display: none;
  }
  .recent-items-slider {
    .splide__arrows {
      .splide__arrow--next {
        right: -2em;
        svg {
          fill: #797979;
        }
      }
      .splide__arrow--prev {
        left: -2em;
        svg {
          fill: #797979;
        }
      }
      .splide__arrow {
        background: none;
      }
    }
    .splide__pagination {
      bottom: -1.5em;
    }
    .viewed-product-card {
      height: 230px;
      border: 5px solid #f7f7f9;
      padding: 0px;
      box-sizing: border-box;
      background: white;
      img {
        height: 75%;
        width: 100%;
        margin: auto;
        border-top: 20px solid white;
        border-bottom: 20px solid white;
        border-right: 30px solid white;
        border-left: 30px solid white;
      }
      pre {
        font-family: var(--primary_font);
        font-weight: bold;
        color: var(--secondary_color);
        font-size: 22px;
        margin-top: 10px;
      }
      p {
        margin-top: -13px;
      }
      .checked {
        color: black;
      }

      .unchecked {
        color: transparent;
        -webkit-text-fill-color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
      }
    }
  }
  .products-page-wrapper {
    .row {
      .flex {
        .pagination {
          width: 100%;
        }
      }
    }
    .items-per-page-wrapper {
      display: none;
    }
    .products-wrapper {
      .product-card {
        .fav-icon {
          display: none;
        }
        .img_div {
          height: 210px !important;
          .favicon-mobile {
            width: auto !important;
            display: block !important;
            position: absolute;
            top: 10%;
            right: 5%;
          }
          img{
            max-height: 210px !important;
          }
          .viewicon-mobile {
            width: auto !important;
            display: block !important;
            position: absolute;
            top: 30%;
            right: 5%;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .products-page-wrapper {
    .recent-items-slider {
      display: none;
    }
  }
}
@media (max-width: 576px) {
  .products-page-wrapper {
    padding: 10px 12px;
  }
}

.fav-icon {
  height: 20px;
  cursor: pointer;
}
