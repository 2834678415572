.summary-wrapper {
  border: 1px solid transparent;
  background: white;
  margin-top: 60px;
  height: fit-content;
  padding: 15px;
  border-radius: 5px;
  z-index: 1;
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  pre {
    font-size: 14px;
    color: #ee5f3b;
    text-decoration: underline;
    font-weight: bold;
    float: right;
    font-family: "Red Hat Display";
  }
  h2 {
    font-size: 17px;
    font-weight: bold;
  }
  .item_count {
    font-size: 15px;
    font-weight: 500;
    color: white;
    background: #ee5f3b;
    margin-top: 0;
  }
  .itemss {
    position: relative;
    display: flex;
    align-items: center;
    height: 70px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    .p_image {
      width: 40px;
    }
    .name {
      min-height: 90%;
      margin-left: 5px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 7px;
      font-size: 0.9rem;
      font-size: 0.9rem;
      width: 60%;
      font-weight: 500;
      color: #404041;
      


      overflow:hidden;
      line-height: 1rem;
      max-height: 2rem;
      -webkit-box-orient: vertical;
      overflow: hidden !important;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      p {
        font-size: 12px;
      }
      .quite {
        width: 65%;
      }
      small {
        color: #cccccc;
        font-size: 12px;
      }
    }
    .price {
      font-weight: bold;
      font-size: 14px;
      box-sizing: border-box;
      position: absolute;
      right: 0;
      color: #404041;
      top: 2%;
    }
    .close{
      position: absolute;
      right: 0;
      color: white;
      line-height: 0;
      bottom:  35%;
      transform: translateY(50%);
      width: 20px;
      height: 20px;
      font-size: 20px;
      cursor: pointer;
      border-radius: 50%;
      background: #4D82B3;
    padding: 2px;
      >svg{
        width: 70%;
        height: 70%;
        line{
          stroke: white;
          fill: white;
        }
      }

    }
  }
  hr {
    width: 95%;
    text-align: center;
    margin-left: 2%;
  }
  .price_div {
    margin: auto;
    position: relative;
    .amout_for {
      font-size: 14px;
      font-weight: 500;
    }
    .amount {
      font-weight: bold;
      font-size: 14px;
      position: absolute;
      right: 0px;
      color: #404041;
    }
  }
  .total_price {
    color: #404041;
    margin: auto;
    .amout_for {
      font-size: 19px;
      font-weight: bold;
    }
    .amount {
      font-weight: bold;
      font-size: 20px;
      float: right;
    }
  }
  .place_order_btn {
    border-radius: 8px;
    background-color: #68aaa9;
    color: white;
    font-weight: bold;
    font-size: 20px;
    height: 45px;
    width: 100%;
	  border: 2px solid $bg_cyan !important;

    // border: 1px solid blue;
    outline: auto;
    &:focus {
      // outline: none;
    }
    &:hover {
      background-color: #68aaa9;
    }
    &:disabled {
	  border: 2px solid $bg_cyan !important;

      // background-color: #cccccc;
    }
  }
  .last_para {
    font-size: 12px;
    margin-top: 16px;
    span {
      color: #ee5f3b;
      font-weight: bold;
    }
  }
}
