.app-modal {
    position: fixed;
    z-index: 30;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    height: 100vh;
    position: fixed;
    &__wrapper {
    //   @apply w-full flex items-start justify-center min-h-screen text-center px-4;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      min-height: 100vh;
      text-align: center;
      padding: 0 4px;
      @screen md {
        @apply p-0;
      }
  
      &--centered {
        // @apply flex items-center;
        display: flex;
        align-items: center;
      }
    }
  
    &__overlay {
    //   @apply fixed inset-0 bg-black bg-opacity-50 transition-opacity;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        opacity: 0.5;
        transition: opacity 0.3s;
    }
  
    &__container {
    //   @apply inline-block bg-white text-left overflow-hidden transform transition-all max-w-xl w-full;
      display: inline-block;
      background-color: white;
      text-align: left;
      overflow: hidden;
      transform: translateZ(0);
      transition: all 0.3s;
      max-width: max-content;
      width: 100%;
      //width: 350px;
      //height: 250px;
      border-radius: 12px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
  
      @screen sm {
        @apply max-w-5xl;
        width: 550px;
  
        &--sm {
          width: 350px;
        }
  
        &--lg {
          width: 800px;
        }
  
        &--xl {
          width: 950px;
          // max-height: 80vh;
          // height: 80vh;
  
        }
      }
  
      &--overflow-visible {
        overflow: visible;
      }
    }
  
    &__header {
        position: relative;
    //   border-bottom: 1px solid rgba(176, 181, 190, 0.2);
    //   padding: 25px 25px 15px 25px;
  
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
  
      &__close {
        position: absolute;
        top: 20px;
        right: 38px;
        cursor: pointer;
        align-self: center;
      }
    }
  
    &__body {
      padding: 40px;
    }
  
    &__footer {
      padding: 0 25px 25px 25px;
  
      &--border {
        border-top: 1px solid rgba(176, 181, 190, 0.2);
        padding-top: 25px;
      }
    }
  }
  
 span{
    letter-spacing: 0px;
color: #797979;
font-size: 15px;
 }