@import './productImages';
@import './deliveryNreturns';
@import './customerphotos';
@import './similaritems';
@import './frequentlybought';
@import './rating';
@import './vieweditems';
@import './productdetails';
@import './reviews';
@import './clientreviews';
@import './itemCard';
@import './revirewsModal';
@import './productsOverviewModal';
@import './deliveryNreturnsModal';

.greenBanner{
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    svg{
        transition: all 0.3s ease-in-out;

    }
    &:hover{
        svg{

            transform: scale(1.02);
        }
    }
   
    >.bannerText{
        z-index: 200;
        position: absolute;
        top: 30%;
        left: 50%;
        opacity: .9;
        transform: translate(-50%, -50%);
        text-align: center;
        @media (max-width: 808px) {
            top: 40%;
            // font-size: 15px;
        }
    }

}
.background{
    background-color: white;
}
.ratings-review-wrapper{
    background-color: #F0F0F0;
}
.mobile-pb-0{
    @media (max-width: 768px) {
        padding-bottom: 0 !important;
    }
}
.show_reviews{
    color:#EE5F3B;
    font-size: 14px;
    text-decoration: underline;
    text-align: center;
    margin-top: 30px;
    i{
        margin-left:5px;
    }
}
.share{
    // border:1px solid red;
    display: flex;
    align-items: center;
    p{
        font-size: 16px;
        font-weight: bold;
        display: inline;
        color:#404041;
        margin-right: 7px;
    }
    a{
        border:2.5px solid #797979;
        color:#797979;
        font-size: 12px;
        font-weight: bold;
        border-radius: 9px;
        margin-left: 13px;
        height:43px;
        width:120px;
        padding:  10px 10px;
        background: transparent;
        text-decoration: none;
     display: flex;
     align-items: center;
    //  justify-content:;
        i{
            font-size: 14px;
            margin-right: 10px;
        }
    }
}
