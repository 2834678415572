@mixin common-btn {
	background-color: transparent;
	font-size: 13px;
	transition-duration: 0.5s;
	outline: none !important;
}
.primary_btn {
	@include common-btn();
	background-color: transparent;
    border: 2px solid #ee5f3b !important;
    color: #ee5f3b;
	width: 133px;
	height: 40px;
	font-weight: bold;
	border-radius: 6px;
	outline: #ee5f3b ;
	&:hover {
		background-color: #ee5f3b;
		color: white;
	}
}
.secondary_btn {
	@include common-btn();
	color: $bg_cyan;
	border: 2px solid $bg_cyan !important;
	width: 133px;
	height: 40px;
	font-weight: bold;
	border-radius: 6px;
	margin-top: -8px;
	&:hover {
		background-color: $bg_cyan;
		color: white;
	}
}
.ternary_btn {
	background-color: $bg_cyan;
	font-size: 13px;
	transition-duration: 0.5s;
	outline: none !important;
	color: white;
	border: 1px solid white;
	font-weight: bold;
	height: 40px;
	width: 85px;
	padding: 5px;
	border-radius: 6px;
	margin-top: -1px;
	&:hover {
		background-color: white;
		border: 2px solid black;
		color: Black;
	}
	&:disabled{
		background-color: #e0e0e0;
		border: 2px solid #e0e0e0;
		color: #9e9e9e;
	}
}
._btn {
	background-color: transparent;
	font-size: 13px;
	transition-duration: 0.5s;
	outline: none !important;
	color: white;
	border: 1px solid white;
	font-weight: bold;
	height: 40px;
	width: 85px;
	padding: 5px;
	border-radius: 6px;
	margin-top: -1px;
	&:hover {
		background-color: white;
		// border: 2px solid white;
		color: Black;
	}
}
._btnTest {
	background-color: transparent;
	font-size: 13px;
	transition-duration: 0.5s;
	outline: none !important;
	color:$bg_cyan;
	border: 1px solid $bg_cyan;
	font-weight: bold;
	height: 35px;
	// width: 100%;
	padding: 5px;
	border-radius: 6px;
	margin-top: -1px;
	&:hover {
		background-color: $bg_cyan;
		border: 2px solid white;
		color: white;
	}
}

.added{
    position: fixed;
    width: 301px;
    height: 80px;
    background-color: #404041;
    border-radius: 10px;
    color: white;
    display: flex;
    padding: 0px 10px;
    align-items: center;
    gap: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	z-index: 999;
	opacity: 0.8;
	transition-duration: 150px;

	visibility: hidden;
}

.load{

	animation: 1.5s shine linear infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px 5px 0px 0px;
    background-size: 200% 100%;
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
}