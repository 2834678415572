.delivery_wrapper{
    height:auto;
    background: white;
    margin-top:50px;
    background: transparent;

    h2{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
    }
    .free_Del{
        position: relative;
        img{
            height:27px;
            width: 44px;
        }
        h3{
            font-size: 18px;
            color:#53ACAA;
            position: absolute;
            left:69px;
            top:5px;
            font-weight: bold;
        }
        p{
            font-size: 16px;
            color:#404041;
            margin-bottom: 8px;
            font-weight: 600;

            img{
                height: 18px;
                margin-left: 7px;
                width: 18px;
            }
        }
        i{
            margin-left: 7px;
        }
        span{
            color:#EE5F3B;
            text-decoration: underline;
            margin-left: 7px;
        }
        h4{
            font-size: 14px;
            color:#404041;
            font-weight: bold;
            margin-top: 40px;
            margin-bottom: 20px;
        }

    }
    .Del,.money_back{
        .flex{
            display: flex;
            align-items: flex-start;
          }
        .t_img{
            height:auto;
            width: 41px;
        }
        .c_img{
            height:auto;
            width: 48px;
        }
        h3{
            font-size: 18px;
            font-weight: bold;
        }
        p{
            color:#AFAFAF;
            font-size: 14px;
            font-weight: 500;
        }
        span{
            font-size: 12px;
            color:#EE5F3B;
            text-decoration: underline;
            font-weight: 500;

        }
    }
}
@media (max-width: 768px) {
    .delivery_wrapper{
            display: none;
        }
}