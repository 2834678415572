.client-reviews-wrapper {
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    padding: 10px 25px;
    
    .review-row {
        padding-top: 20px;
        padding-bottom: 20px;
        .user_div {
            position: relative;
            h3 {
                position: absolute;
                top: 10px;
                left: 45px;
                font-size: 12px;
                color: #404041;
                font-weight: bold;
            }
            pre {
                position: absolute;
                top: 22px;
                left: 45px;
                font-size: 14px;
                font-weight: 500;
                color: #797979;
            }
            p {
                color: #53acaa;
                font-size: 12px;
                position: absolute;
                left: 45px;
                top: 44px;
                font-weight: 600;
                i {
                    margin-right: 7px;
                }
            }
        }
        .review-desc-wrapper {
            display: flex;
            .flex {
                display: flex;
                align-items: baseline;
                margin-bottom: 15px;
                .rating-stars {
                    flex: 1;
                }
                .date-wrapper {
                    display: flex;
                    align-items: center;
                    width: 30%;
                    padding-bottom: 2px;
                    position: absolute;
                    right: 10%;
                    img {
                        height: 12px;
                        padding-right: 3px;
                    }
                    .date {
                        font-size: 12px;
                        color: #afafaf;
                        margin-bottom: 0px;
                    }
                }
            }
            .review-text {
                color: #797979;
                font-size: 12px;
                font-weight: 500;
            }
        }
        .btn {
            button {
                height: 40px;
                width: 125px;
                margin-top: 20px;
                color: #afafaf;
                border: none;
                background: transparent;
                border: 1px solid #afafaf;
                border-radius: 6px;
                font-size: 14px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 150ms ease-in-out;
                outline: none;
                &:hover {
                    background-color: #53acaa;
                    color: white;
                    > svg {
                       path{
                            stroke: white;
                       }
                        margin-right: 6px;
                        height: 19px;
                    } 
                }
            }
        }
        &:last-child{
            border-bottom: 0px !important;
        }
    }
}
@media (max-width: 768px) {
    .client-reviews-wrapper {
        display: none;
    }
}
