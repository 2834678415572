.counter_div {
    height: 25px;
    // width: 115px;
    padding: 0 30px;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #808080;
    border: 2px solid #b3b0b0 !important;
    border-radius: 5px;
    p {
      margin-top: 16px;
      font-size: 17px;
    }
    >button{
      outline: none;
      transition: all 0.3s ease-in-out;
      &:active{
        color : #53acaa;
        transform: scale(1.1);
      }
    }
    .minus {
      border: none;
      border-right: 1px solid #808080;
      position: absolute;
      left: 0px;
      color: #808080;
      background-color: transparent;
      height: 55%;
      width: 28%;
    }
    .plus {
      border: none;
      border-left: 1px solid #808080;
      color: #808080;
      position: absolute;
      right: 0px;
      background-color: transparent;
      height: 55%;
      width: 28%;

    }
  }

  .saving{
    // background-color: ;
    // border: 1px solid #53ACAA;
    border-radius: 5px;
    background-image: white;
    box-shadow: 0 0 7px #2196f3;
    // filter: drop-shadow(-1px -1px 11px #4ABFD2);
    padding: 13px 5px;
    >span{
      font-size: 18px;
      color: black;
    }
  }