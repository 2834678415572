:root{
    --font_style:'Red Hat Display';

    --heading_size:30px;
    --subheading_size:23px;
    --heading_color:#32324b;

    --bg_color_cyan:#53ACAA;
    --bg_color_orange:#EE5F3B;

}

.heading_div{
    background-color: var(--bg_color_orange);
    height:130px;
    b{
        font-size: var(--heading_size);
        color:white;
    }
}
////
/// 
.mobile-hidden{
    @media (max-width: 768px) {
        opacity: 0 !important;
      }
}

.help_container{
    .route_help{
        
	    font-size: 14px;
	    color:#707070;
	    margin-top: 50px;
        margin-bottom: 20px;

	    span{
			color:#404041;
	    }
    }
    .row{
        .left_div{
            margin-top: 0px;
            background-color: inherit;
            h1{  
                color:#404041;
                font-size: var(--subheading_size);
            }
            ul{
                list-style-type: circle;
                color:#404041;
                line-height: 35px;
                padding-inline-start: 0px;
                font-size: 16px;
                span{
                    color:var(--bg_color_orange)
                }
            }
            p{
                color:#404041;
                font-size: 16px;
                line-height: 22px;
                span{
                    color:var(--bg_color_orange)

                }

            }
            .block_div{
                height:60px;
                // padding-top: 20px;
                width:100%;
                border:2px solid #AFAFAF;

                .box{
                    height:100%;
                    width:65px;
                    background-color:#AFAFAF ;
                    float: left;
                }
                p{
                    font-size: 13px;
                    color:#404041;
                    margin-top: 20px;
                    
                }

            }
            .block_black_div{
                border:2px solid #404041;
                .box{
                    background: #404041;
                }
            }
            .block_black_div2nd{
                
                height:14%;
                padding: 0px;
                font-size: 12px;
                border:2px solid #404041;
                .box{
                    background: #404041;
                    width:140px;
                    height: 100%;
                    img{
                        height: 100%;
                        width: 90%;
                    }
                }
            }
            .block_black_div3{
                height:6%;

                .box{
                    height:100%;
                    width:175px;
                }
            }
            .input_div_help{
                border:1px solid #AFAFAF;
                span{
                    background: white;
                    color:black;
                    width:40px;
                }
            }
            .grey_div_help{
                background-color: #E7E4D7;
                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                button{
                    // background-color: var(--bg_color_cyan);
                    height:43px;
                    width:170px;
                    font-size: 16px;
                    background-color: #53acaa;
                    color: white;
                    border-radius: 5px;
                }
            }
        }
        .right_div{
            h1{
                color:#404041;
                font-size: var(--subheading_size);
            }
            ul{
                list-style: none;
                padding-inline-start: 0px;
                color:#797979;
                font-size: 16px;
                li {
                    cursor: pointer;
                }
            }
            .highlight{
                color:var(--bg_color_orange);
                font-weight: bold;
                text-decoration: underline;
            }

        }

    }
}

@media screen and (min-width:300px) and (max-width: 480px) 
{
.help_container{
    .row{
        .left_div{
    
    
         .grey_div_help{
             display: flex;
             justify-content: center;
             align-items: center;
             flex-direction: column;
         }
         .block_black_div{
             height:2%;
         }
         .block_black_div2nd{
             height: 15%;
                
         }
         .block_black_div3{
             height:8%;

             p{
                 margin-top: 30px;
             }
         }
 
        }
        .right_div{
            opacity: 0px;
        }
    
    }
}
}