.checkout-address {
  .save-address-btn {
    background-color: #53acaa;
    color: white;
    font-weight: bold;
    font-size: 12px;
    border-radius: 10px;
    padding: 1rem;
    // margin-left: 100px;
    z-index: 1;
    position: relative;
    &:focus{
      outline: none;
    }
  }
  form{
    padding-right: 30px;

    @media (max-width: 608px) {
    padding-right: 0;

      >div{
        width: 100%;
      }
    }
  }
  .focusedTrue{
    >span{
      top: 10% !important;
      font-size: 12px;
    }
  }
  .box-search {
    position: relative;
    width: 100%;
    input {
      position: relative;
      border: none;
      outline: none;
      height: 42px;
      width: 100%;
      font-size: 22px;
      padding-top: 16px;
      padding-bottom: 6px;
      box-sizing: border-box;
      border-radius: 5px;
      border: 2px solid #afafaf;
      font-size: 15px;
      padding-left: 10px;
      background: transparent;
      z-index: 2;
      transition: 0.25s;
    }
    span {
      display: inline-block;
      position: absolute;
      left: 0;
      color: rgba(0, 0, 0, 0.5);
      z-index: 1;
      transition: 0.25s;
    }
    a {
      position: absolute;
      top: 25%;
      right: 3%;
      text-decoration: underline;
    }

    input + span {
      top: 25%;
      left: 2%;
    }
    input:focus + span {
      top: 10%;
      font-size: 12px;
      color : #53acaa;

    }
    input:focus{
      border: 2px solid #53acaa;
    }


  }
}
