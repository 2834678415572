.rating-wrapper{
    margin-top: 50px;
    h3{
        color:#404041;
        font-size: 20px;
        font-weight: bold;
    }
    .rating-box{
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: white;
        border-radius: 15px;
        margin-top: 40px;
        .row{
            .ratings{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                pre{
                    color:#797979;
                    font-size: 12px;
                }
                h1{
                    font-size: 42px;
                    color:#404041;
                    font-weight: bold;
                }
            }
            .progress_div{
                width: 90%;
                height: 33px;
                display: flex;
                justify-content: space-evenly;
                align-items:center;
                // flex-direction: column;
                pre{
                    margin-top: 15px;
                    color: #797979;
                    font-size: 16px;
                    font-weight: 500;
    
                }
                .progress{
                    border-radius: 40px;
                    height:22px;
                    width:69%;
                    .progress-bar{
                        background: #53ACAA;
                    }
                }
            }
            .share{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                p{
                    font-size: 14px;
                }
                button{
                    border:2px solid #53ACAA;
                    color:#53ACAA;
                    font-weight: bold;
                    font-size: 12px;
                    border-radius: 8px;
                    background-color: transparent;
                    height:45px;
                    width: 180px;
                }
            }
        }
    }
  
}
@media (max-width: 768px) {
    .rating-wrapper{
            display: none;
        }
}